import ApolloClient from 'apollo-boost';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import Empty from './components/Empty/Empty';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import './index.css';
import config from './lib/config';
import registerServiceWorker from './registerServiceWorker';
import theme from './theme';

const apolloClient = new ApolloClient({ uri: config.apiURL });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <Router>
          <section>
            <Navbar />
            <Switch>
              <Route exact path="/" component={App} />
              <Route render={() => <Empty title="Page not Found" />} />
            </Switch>
            <Footer />
          </section>
        </Router>
      </ApolloHooksProvider>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById('root') as HTMLElement,
);

registerServiceWorker();
