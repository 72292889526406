import styled from 'styled-components';
import {Laptop, TabletH, TabletV} from '../Breakpoints/Breakpoints';

export const Container = styled.div`
  max-width: 72rem;
  margin: 0 auto;
  width: 100%;
`;

export const LargeContainer = styled.div`
  max-width: 96.875rem;
  margin: 0 auto;
`;

export const BootstrapContainer = styled.div`
  display: block;
  margin: 0 auto;

  @media (${TabletV}) {
    width: 750px;
  }
  @media (${TabletH}) {
    width: 970px;
  }
  @media (${Laptop}) {
    width: 1170px;
  }
`;
