import * as React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface Props {
  loading?: boolean
  error?: any
  data?: any

  render(data: any): React.ReactElement<any> | null

  renderError?(error: any): React.ReactElement<any> | null
}

function AsyncComponent(props: Props) {
  const {loading, error, data, render, renderError} = props;

  if (loading) {
    return <LoadingSpinner/>;
  }

  if (error) {
    if (renderError) {
      return renderError(error);
    }
    return <div>{error.message}</div>;
  }

  if (!data) {
    return <div>data not found!</div>;
  }

  return render(data);
}

export default AsyncComponent;