import styled from 'styled-components';
import Text from '../../components/Text/Text';
import {Laptop, TabletV} from '../Breakpoints/Breakpoints';
import {Title} from '../Title/Title';

export const StyledSection = styled('div')`
  width: 100%;
  margin: 0 auto;
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;

  &.desktopSidebar {
    display: none;
  }

  @media (${TabletV}) {
    max-width: 38.75rem;
  }

  @media (${Laptop}) {
    padding-top: 0;
    &.desktopSidebar {
      display: flex;
    }
  }
`;

export const IMG = styled('img')`
  width: 100%;

  @media print {
    display: none;
  }
`;

export const Container = styled('div')`
  background: #fff;
  margin-bottom: 0.9375rem;
`;

export const Info = styled('div')`
  padding: 1.5rem;
  border-bottom: 1px solid #dbdbdb;
  text-align: left;

  @media (${TabletV}) {
    padding: 2.5rem;
  }
`;

export const Details = styled('div')`
  background: #fff;
  padding: 0 1.25rem;
  border-bottom: 1px solid #dbdbdb;
`;

export const Days = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DayItem = styled('div')`
  > p {
    padding-bottom: 0.5rem;
  }
`;

export const SpaceBreak = styled('div')`
  width: 3.75rem;

  @media (${TabletV}) {
    width: 9.375rem;
  }
`;

export const AngleRight = styled('img')`
  display: block;
  margin: 0 auto;
`;

export const PriceSection = styled('div')`
  padding: 1rem;
  border-bottom: 1px solid #dbdbdb;

  &:last-child {
    border-bottom: 0;
  }

  @media (${TabletV}) {
    padding: 1.25rem;
  }
`;

export const PriceItems = styled('div')`
  display: flex;
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
`;

export const Footer = styled('div')`
  padding: 1.25rem 1rem 1.5rem;

  @media (${TabletV}) {
    padding: 1.625rem 1.25rem 1.875rem;
  }
`;

export const StyledTitle = styled(Title)`
  color: #2f4427;
  padding-bottom: 1.25rem;

  @media (${TabletV}) {
    padding-bottom: 1.75rem;
  }
`;

export const StyledText = styled(Text)`
  color: #6b6b6b;
`;

export const StyledListItem = StyledText.withComponent('li');

export const StyledList = styled.ul`
  list-style: initial;
  margin-left: 20px;
`;

export const BoldText = styled(Text)`
  color: #000000;
`;

export const Total = styled.h1`
  font-size: 1.375em;
  color: #2f4427;
  font-weight: bold;
  text-transform: uppercase;

  @media (${TabletV}) {
    font-size: 1.875em;
  }
`;

export const Type = styled(BoldText)`
  padding-bottom: 0.5rem;

  @media (${TabletV}) {
    padding-bottom: 0.75rem;
  }
`;

export const Included = styled('div')`
  padding-top: 1rem;
  padding-bottom: 1.25rem;
  .field {
    color: #000;
    padding-bottom: 0.25rem;
  }

  @media (${TabletV}) {
    padding-bottom: 1.75rem;
  }
`;

export const NotIncluded = styled(Included)`
  padding-top: 0;
  padding-bottom: 1.125rem;

  @media (${TabletV}) {
    padding-bottom: 1.375rem;
  }
`;

export const SmallText = styled(Text)`
  color: #6b6b6b;
`;
