import * as React from 'react';
import styled from 'styled-components';
import EnvelopeIcon from '../../assets/images/envelope.png';
import PhoneIcon from '../../assets/images/phone.png';
import {Laptop} from '../Breakpoints/Breakpoints';
import {Title4, Title5} from '../Title/Title';

interface Props {
  className?: string;
}

const StyledSection = styled('section')`
  background: #fff;
  padding: 2.625rem 2.5rem 2.5rem;
  display: none;

  @media (${Laptop}) {
    display: block;
  }
`;

const Item = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 0.875rem;

  &:last-child {
    padding-bottom: 0;
  }

  img {
    padding-right: 1rem;
  }
`;

const StyledTitle4 = styled(Title4)`
  padding-bottom: 1.25rem;
`;

const StyledTitle5 = styled(Title5)`
  color: #6b6b6b;
  * {
    color: inherit;
    text-decoration: none;
  }
`;

function ContactCard(props: Props) {
  const {className} = props;
  const phone = '1-888-839-2086';
  const email = 'bookings@under30experiences.com';
  return (
    <StyledSection className={className}>
      <StyledTitle4 weight="bold">Need Help?</StyledTitle4>
      <Item>
        <img src={PhoneIcon} alt=""/>
        <StyledTitle5>
          <a href={`tel:${phone}`}>{phone}</a>
        </StyledTitle5>
      </Item>
      <Item>
        <img src={EnvelopeIcon} alt=""/>
        <StyledTitle5>
          <a href={`mailto:${email}`}>{email}</a>
        </StyledTitle5>
      </Item>
    </StyledSection>
  );
}

export default ContactCard;
