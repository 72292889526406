import * as React from 'react';
import styled from 'styled-components';
import LogoImage from '../../assets/images/logo.png';
import PhoneIcon from '../../assets/images/phone.png';
import { Laptop, TabletV } from '../Breakpoints/Breakpoints';

interface Props {
  className?: string;
}

const StyledSection = styled.section`
  background: #fff;
  display: flex;
  align-item: center;
  justify-content: space-between;
  padding: 0.875rem 0.625rem;
  border-bottom: 1px solid #e3e3e3;

  @media (${Laptop}) {
    justify-content: center;
    padding: 1.875rem 1rem 1.375rem;
  }
`;

const Logo = styled.img`
  height: 40px;

  @media (${TabletV}) {
    height: auto;
  }
`;

const Contact = styled.div`
  display: flex;
  align-items: center;

  @media (${Laptop}) {
    display: none;
  }
`;

const Text = styled.p`
  font-size: 0.875em;
  color: #669157;
  padding-right: 0.625rem;
  padding-left: 0.375rem;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

function Navbar({ className }: Props) {
  const phone = '1-888-839-2086';
  return (
    <StyledSection className={className}>
      <Logo src={LogoImage} />
      <Contact>
        <img src={PhoneIcon} alt="" />
        <Text>
          <a href={`tel:${phone}`}>Contact Us</a>
        </Text>
      </Contact>
    </StyledSection>
  );
}

export default Navbar;
