import * as React from 'react';
import Moment from 'react-moment';
import AngleRightIcon from '../../assets/images/angle-right.png';
import currencyFormatter from '../../lib/currencyFormatter';
import { IBooking } from '../../types';
import {
  AngleRight,
  BoldText,
  Container,
  DayItem,
  Days,
  Details,
  Footer,
  IMG,
  Included,
  Info,
  NotIncluded,
  PriceItems,
  PriceSection,
  SmallText,
  SpaceBreak,
  StyledList,
  StyledListItem,
  StyledSection,
  StyledText,
  StyledTitle,
  Total,
  Type,
} from '../CheckoutSidebar/CheckoutSidebar';
import ContactCard from '../ContactCard/ContactCard';
import { FieldTitle } from '../Title/Title';

interface Props {
  booking: IBooking;
}

function ReceiptOrder(props: Props) {
  const { booking } = props;
  return (
    <StyledSection>
      <IMG src={booking.trip.location.image} />
      <Container>
        <Info>
          <StyledTitle weight="bold">Trip Summary</StyledTitle>
          <Type weight="bold">{booking.trip.location.title}</Type>
          <StyledText>{booking.trip.location.description}</StyledText>
          {!!booking.trip.location.included.length && (
            <Included>
              <FieldTitle className="field" weight="bold">
                Included
              </FieldTitle>
              <StyledList>
                {booking.trip.location.included.map((item: string, index: number) =>
                  <StyledListItem key={index}>{item}</StyledListItem>)}
              </StyledList>
            </Included>
          )}

          {!!booking.trip.location.notIncluded.length && (
            <NotIncluded>
              <FieldTitle className="field" weight="bold">
                Not Included
              </FieldTitle>
              <StyledList>
                {booking.trip.location.notIncluded.map((item: string, index: number) =>
                  <StyledListItem key={index}>{item}</StyledListItem>)}
              </StyledList>
            </NotIncluded>
          )}
          <SmallText size="verysmall">
            * Please wait to book your flight until you receive an email from us with more details.
          </SmallText>
        </Info>

        <Details>
          <PriceSection>
            <Days>
              <DayItem>
                <BoldText weight="bold">
                  <Moment format={'dddd'} date={booking.trip.startDate} />
                </BoldText>
                <StyledText>
                  <Moment format={'D MMMM, YYYY'} date={booking.trip.startDate} />
                </StyledText>
              </DayItem>

              <SpaceBreak>
                <AngleRight src={AngleRightIcon} />
              </SpaceBreak>

              <DayItem>
                <BoldText weight="bold">
                  <Moment format={'dddd'} date={booking.trip.endDate} />
                </BoldText>
                <StyledText>
                  <Moment format={'D MMMM, YYYY'} date={booking.trip.endDate} />
                </StyledText>
              </DayItem>
            </Days>
          </PriceSection>

          <PriceSection>
            <PriceItems>
              <BoldText weight="bold"># of Adults</BoldText>
              <StyledText>{booking.travelers.length}</StyledText>
            </PriceItems>
          </PriceSection>
          <PriceSection>
            <PriceItems>
              <BoldText weight="bold">Subtotal</BoldText>
              <StyledText>{currencyFormatter.format(booking.order.amount / 100)}</StyledText>
            </PriceItems>
          </PriceSection>

          {!!booking.order.couponIds.length && (
            <PriceSection>
              <PriceItems>
                <BoldText weight="bold">{`Coupon: ${booking.order.couponIds.join(', ')}`}</BoldText>
              </PriceItems>
            </PriceSection>
          )}

          <PriceSection>
            <PriceItems>
              <BoldText weight="bold">Total</BoldText>
              <Total>{currencyFormatter.format(booking.order.amount / 100)}</Total>
            </PriceItems>
          </PriceSection>
        </Details>

        {booking.order.type === 'deposit' && (
          <Footer>
            <StyledText>We will personally reach out to charge the remaining balance.</StyledText>
          </Footer>
        )}
      </Container>

      <ContactCard />
    </StyledSection>
  );
}

export default ReceiptOrder;
