import styled from 'styled-components';
import * as breakpoints from '../Breakpoints/Breakpoints';

interface IText {
  weight?: 'bold' | 'semibold'
  size?: 'large' | 'small' | 'verysmall'
  align?: 'center'
  transform?: 'uppercase'
}

const Text =
  styled('p') <IText>`
  font-size: 1em;
  color: #6b6b6b;
  line-height: 1.3;
  font-family: 'Raleway', sans-serif;
  
  ${({weight}) => {
    switch (weight) {
      case 'bold':
        return 'font-weight: 700;';
      case 'semibold':
        return 'font-weight: 600;';
      default:
        return '';
    }
  }}

  ${({size}) => {
    switch (size) {
      case 'large':
        return 'font-size: 1.4375em;';
      case 'small':
        return 'font-size: 0.875em;';
      case 'verysmall':
        return 'font-size: 0.9375em;';
      default:
        return '';
    }
  }}
  
  ${({align}) => {
    switch (align) {
      case 'center':
        return 'text-align: center;';
      default:
        return '';
    }
  }}
  
  ${({transform}) => {
    switch (transform) {
      case 'uppercase':
        return 'text-transform: uppercase;';
      default:
        return '';
    }
  }}  
  
  @media (${breakpoints.TabletV}) {
    font-size: 1.125em;

    ${({size}) => {
      switch (size) {
        case 'large':
          return 'font-size: 1.4375em;';
        case 'verysmall':
          return 'font-size: 0.9375em;';
        default:
          return '';
      }
    }}
  }
`;

export default Text;