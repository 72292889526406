import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import ReceiptBG from './assets/images/receipt-bg.jpg';
import AsyncComponent from './components/AsyncComponent/AsyncComponent';
import { Desktop, Laptop, TabletH, TabletV } from './components/Breakpoints/Breakpoints';
import Button from './components/Button/Button';
import { BootstrapContainer } from './components/Container/Container';
import Empty from './components/Empty/Empty';
import ReceiptOrder from './components/ReceiptOrder/ReceiptOrder';
import ReferralCandy from './components/ReferralCandy/ReferralCandy';
import Text from './components/Text/Text';
import { Title } from './components/Title/Title';
import config from './lib/config';
import { IBooking } from './types';

const ReceiptArea = styled('div')`
  background: #fff;
  padding: 1.75rem 1rem;

  @media (${TabletV}) {
    padding: 2.25rem 1rem;
  }

  @media (${TabletH}) {
    padding: 2.75rem 1rem;
  }

  @media (${Laptop}) {
    padding: 3.25rem 1rem;
  }

  @media (${Desktop}) {
    padding: 3.5rem 1rem;
  }
`;

const TextSection = styled('div')``;

const StyledTitle = styled(Title)`
  padding-bottom: 1rem;

  @media (${TabletV}) {
    padding-bottom: 1.25rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5rem;
  }

  @media (${Desktop}) {
    padding-bottom: 1.75rem;
  }
`;

const StyledText = styled(Text)`
  padding-bottom: 1rem;

  @media (${TabletV}) {
    padding-bottom: 1.25rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5rem;
  }

  @media (${Desktop}) {
    padding-bottom: 1.75rem;
  }

  &:last-child {
    padding-bottom: 0;
  }

  b {
    color: #334d2b;
    font-weight: 700;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 1.25rem;

  > button {
    margin-bottom: 0.75rem;
  }

  @media (${TabletV}) {
    flex-direction: row;
    padding-top: 1.875rem;

    > button {
      margin-bottom: 0;
    }
  }

  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  max-width: none;

  @media (${TabletV}) {
    max-width: 15.125rem;

    &:first-child {
      margin-right: 0.875rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const SummaryArea = styled('div')`
  background-image: url(${ReceiptBG});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
  padding: 0.875rem 1rem;

  @media (${TabletV}) {
    padding: 1.25rem 1rem;
  }

  @media (${TabletH}) {
    padding: 1.375rem 1rem 3.5rem;
  }

  @media (${Laptop}) {
    padding: 2.75rem 1rem 4rem;
  }

  @media (${Desktop}) {
    padding: 3.125rem 1rem 5.625rem;
  }
`;

const GET_BOOKING = gql`
    query getBooking($bookingId: ID!) {
        getBooking(bookingId: $bookingId) {
            bookingId
            tripId
            createTime
            referralCandySignature
            travelers {
                travelerId
                firstName
                lastName
                email
            }
            trip {
                tripId
                locationId
                startDate
                endDate
                detailsUrl
                location {
                    title
                    country
                    description
                    included
                    notIncluded
                    image
                }
            }
            order {
                amount
                couponIds
                type
            }
        }
    }
`;

interface Props extends RouteComponentProps {

}

function App(props: Props) {
  const [bookingId] = useQueryParam('booking_id', StringParam);
  const [, setLocationId] = useQueryParam('location_id', StringParam);
  const query = useQuery<{ getBooking: IBooking }>(GET_BOOKING, { variables: { bookingId } });

  return (
    <AsyncComponent
      {...query}
      renderError={() => <Empty title="Receipt not found" />}
      render={({ getBooking: booking }) => {
        setLocationId(booking.trip.locationId);
        return (
          <>
            {config.enableReferralCandy && <ReferralCandy booking={booking} />}
            <ReceiptArea>
              <BootstrapContainer>
                <TextSection>
                  <StyledTitle color="primary" weight="semibold">
                    Congratulations!
                  </StyledTitle>
                  <StyledText>
                    Please note this is a receipt of payment NOT a confirmation of your seat on this trip.
                  </StyledText>
                  <StyledText>Here is the communication you can expect from us:</StyledText>
                  <StyledText>
                    {
                      booking.trip.location && (
                        <p>
                            <span
                              style={{
                                color: '#92b784',
                                fontWeight: 600,
                              }}
                            ><b>1. CONFIRMATION: </b> Your confirmation number is: {booking.bookingId}</span>. We'll be
                          in touch with you within 24 hours to confirm your seat on
                          the {booking.trip.location.title} trip.
                        </p>
                      )
                    }
                  </StyledText>
                  <StyledText>
                    <b>2. GREEN-LIGHT/FLIGHT INFO: </b> Within 4-6 months of your trip's departure we'll send you
                    instructions on booking your flights. Please wait for this email before booking flights or making
                    any other trip arrangements.
                  </StyledText>
                  <StyledText>
                    <b>3. PRE-DEPARTURE: </b>
                    You can always find specifics like a packing
                    list and health/safety info for your trip in the "Trip Details"
                    tab on the trip page. We'll also send you this info by email
                    within 30 days of your trip.
                  </StyledText>
                </TextSection>
                <ButtonGroup className="no-print">
                  <StyledButton variant="primary" transform="capitalize" onClick={() => window.print()}>
                    Print Receipt
                  </StyledButton>
                  <StyledButton variant="invertedPrimary" transform="capitalize">
                    <a href={booking.trip.detailsUrl} target="_blank" rel="noopener noreferrer">
                      Trip Details
                    </a>
                  </StyledButton>
                </ButtonGroup>
              </BootstrapContainer>
            </ReceiptArea>
            <SummaryArea>
              <ReceiptOrder booking={booking} />
            </SummaryArea>
          </>
        );
      }}
    />
  );
}

export default App;
