import * as React from 'react';
import { LocationStyledText, SelectDatesContainer, SelectDatesHeader, TripDatesSection } from '../../LocationStyles';
import { LargeContainer } from '../Container/Container';

interface Props {
  title: string
}

function Empty(props: Props) {
  const { title } = props;
  return (
    <TripDatesSection>
      <LargeContainer>
        <SelectDatesContainer>
          <SelectDatesHeader weight="bold">
            {title}
          </SelectDatesHeader>
          <LocationStyledText align="center">
            <a href="https://www.under30experiences.com/trips">Checkout our destinations</a>
          </LocationStyledText>
        </SelectDatesContainer>
      </LargeContainer>
    </TripDatesSection>
  );
}

export default Empty;
