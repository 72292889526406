import styled from 'styled-components';
import * as breakpoints from '../Breakpoints/Breakpoints';

interface ITitle {
  color?: 'primary' | 'white' | 'green' | 'grey'
  weight?: 'bold' | 'semibold'
  transform?: 'uppercase'
}

export const Title =
  styled('h1') <ITitle>`
  margin: 0;
  line-height: 1.2;
  font-size: 1.375em;
  font-family: 'Raleway', sans-serif;
  
  ${({color}) => {
    switch (color) {
      case 'primary':
        return 'color: #2f4427;';
      case 'white':
        return 'color: #fff;';
      case 'green':
        return 'color: #659254;';
      case 'grey':
        return 'color: #6b6b6b;';
      default:
        return 'color: #000;';
    }
  }};
  
  ${({weight}) => {
    switch (weight) {
      case 'bold':
        return 'font-weight: 700;';
      case 'semibold':
        return 'font-weight: 600;';
      default:
        return '';
    }
  }};

  ${({transform}) => {
    switch (transform) {
      case 'uppercase':
        return 'text-transform: uppercase;';
      default:
        return '';
    }
  }};

  @media (${breakpoints.TabletV}) {
    font-size: 1.625em;
  }

  @media (${breakpoints.TabletH}) {
    font-size: 1.875em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 2em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 2.3125em;
  }
`;

export const Title2 = styled(Title)`
  font-size: 1.125em;

  @media (${breakpoints.TabletH}) {
    font-size: 1.25em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 1.5em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 1.5625em;
  }
`;

export const Title3 = styled(Title)`
  font-size: 1.125em;

  @media (${breakpoints.TabletV}) {
    font-size: 1.25em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 1.5em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 1.5625em;
  }
`;

export const Title4 = styled(Title)`
  font-size: 1.25em;

  @media (${breakpoints.TabletV}) {
    font-size: 1.375em;
  }

  @media (${breakpoints.TabletH}) {
    font-size: 1.5em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 1.5625em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 1.75em;
  }
`;

export const Title5 = styled(Title)`
  font-size: 1.125em;

  @media (${breakpoints.TabletV}) {
    font-size: 1.25em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 1.375em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 1.4375em;
  }
`;

export const Title6 = styled(Title)`
  font-size: 1.125em;

  @media (${breakpoints.TabletV}) {
    font-size: 1.5em;
  }

  @media (${breakpoints.Laptop}) {
    font-size: 1.625em;
  }

  @media (${breakpoints.Desktop}) {
    font-size: 1.875em;
  }
`;

export const FieldTitle = styled(Title)`
  font-size: 1em;
  padding-bottom: 0.25rem;

  @media (${breakpoints.TabletV}) {
    font-size: 1.125em;
  }

  @media (${breakpoints.TabletH}) {
    padding-bottom: 0.75rem;
  }
`;
