import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

const Loading = styled.div`
  height: 30vh;
  padding-top: 3rem;
  text-align: center;
`;

const LoadingSpinner = () => {
  return (
    <Loading>
      <FontAwesome spin={true} name="spinner" size="5x" />
    </Loading>
  );
};

export default LoadingSpinner;
