import styled from 'styled-components';
import {Desktop, Laptop, TabletH, TabletV} from './components/Breakpoints/Breakpoints';
import Text from './components/Text/Text';
import {Title} from './components/Title/Title';

export const StyledText = styled(Text)`
  padding-bottom: 1rem;

  @media (${TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${TabletH}) {
    padding-bottom: 1.25rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5rem;
  }
`;

export const Total = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 2rem;

  @media (${TabletV}) {
    padding-left: 0;
    flex: 1;
  }
`;

export const TripDatesSection = styled('div')`
  background: #e2eddc;
  padding: 1rem;

  @media (${TabletV}) {
    padding: 1.5rem 1rem;
  }

  @media (${TabletH}) {
    padding: 2rem 1rem;
  }

  @media (${Desktop}) {
    padding: 2rem 0;
  }
`;

export const SelectDatesContainer = styled('div')`
  background: #fff;
  padding: 1.25rem 1.5rem 1rem;

  @media (${TabletV}) {
    padding: 1.75rem 1.875rem 1.25rem;
  }

  @media (${TabletH}) {
    padding: 2.125rem 2.25rem 1.625rem;
  }

  @media (${Laptop}) {
    padding: 2.5rem 2.8125rem 2rem;
  }
`;

export const SelectDatesHeader = styled(Title)`
  padding-bottom: 1.5rem;
  text-align: center;

  @media (${TabletV}) {
    padding-bottom: 2.25rem;
  }

  @media (${TabletH}) {
    padding-bottom: 2.75rem;
  }

  @media (${Laptop}) {
    padding-bottom: 3.125rem;
  }
`;

export const LocationStyledText = styled(Text)`
  padding-bottom: 1rem;

  @media (${TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${TabletH}) {
    padding-bottom: 1.25rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5rem;
  }
`;
