import * as React from 'react';
import styled from 'styled-components';
import Text from '../../components/Text/Text';
import { Laptop, TabletH, TabletV } from '../Breakpoints/Breakpoints';

const StyledSection = styled('section')`
  background: #e2eddc;
  padding: 0 1rem 5.5rem;
  text-align: center;

  @media (${TabletV}) {
    padding: 0 1rem 7rem;
  }

  @media (${Laptop}) {
    padding: 0 1rem 2.5rem;
  }
`;

const Links = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;

  @media (${TabletV}) {
    padding-bottom: 1.125rem;
  }

  @media (${TabletH}) {
    padding-bottom: 1.375rem;
  }

  @media (${Laptop}) {
    padding-bottom: 1.5625rem;
  }
`;

const StyledText = styled(Text)`
  font-size: 0.6875rem;
  color: #2f4427;

  a {
    color: #2f4427;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function Footer() {
  return (
    <StyledSection>
      <Links>
        <StyledText weight="bold">
          <a href="https://www.under30experiences.com/terms-of-service" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
        </StyledText>
      </Links>
      <StyledText>&copy; 2018 Shadow Concepts LLC. All rights reserved.</StyledText>
      <StyledText>
        “Under30Experiences,” “U30X” &amp; all associated proprietary marks are trademarks of Shadow Concepts LLC &amp;
        its related entities.
      </StyledText>
      <StyledText>Other third-party trademarks are the property of their respective owners.</StyledText>
    </StyledSection>
  );
}

export default Footer;
