/* tslint:disable */
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { IBooking } from '../../types';

interface Props {
  booking: IBooking
}

function ReferralCandy(props: Props) {
  const { booking } = props;

  useEffect(() => {
    (function (e) {
      // @ts-ignore
      let t, n, r, i, s, o, u, a, l, c, h, p, d, v, z;
      z = "script";
      l = "refcandy-purchase-js";
      c = "refcandy-mint";
      p = "go.referralcandy.com/purchase/";
      t = "data-app-id";
      r = {
        email: "a",
        fname: "b",
        lname: "c",
        amount: "d",
        currency: "e",
        "accepts-marketing": "f",
        timestamp: "g",
        "referral-code": "h",
        locale: "i",
        "external-reference-id": "k",
        signature: "ab"
      };
      i = e.getElementsByTagName(z)[0];
      // @ts-ignore
      s = function (e, t) {
        if (t) {
          return "" + e + "=" + encodeURIComponent(t)
        } else {
          return ""
        }
      };
      // @ts-ignore
      d = function (e) {
        // @ts-ignore
        return "" + p + h.getAttribute(t) + ".js?aa=75&"
      };
      if (!e.getElementById(l)) {
        h = e.getElementById(c);
        if (h) {
          o = e.createElement(z);
          o.id = l;
          a = function () {
            let e;
            e = [];
            for (n in r) {
              // @ts-ignore
              u = r[n];
              v = h.getAttribute("data-" + n);
              e.push(s(u, v))
            }
            return e
          }();
          // @ts-ignore
          o.src = "//" + d(h.getAttribute(t)) + a.join("&");
          // @ts-ignore
          return i.parentNode.insertBefore(o, i)
        }
      }
    })(document);
  }, []);

  const appId = 'te2ot4j8i833gz3a8vy0zo33m';
  const currency = 'USD';
  const [{ firstName, lastName, email }] = booking.travelers;
  const timestamp = moment(booking.createTime).unix();

  return (
    <div
      id="refcandy-mint"
      data-app-id={appId}
      data-fname={firstName}
      data-lname={lastName}
      data-email={email}
      data-amount={booking.order.amount / 100}
      data-currency={currency}
      data-timestamp={timestamp}
      data-external-reference-id={booking.bookingId}
      data-signature={booking.referralCandySignature}
    />
  )
}

export default ReferralCandy;
