import styled from 'styled-components';
import * as breakpoints from '../Breakpoints/Breakpoints';

interface IButton {
  size?: 'small'
  variant?: 'primary' | 'invertedPrimary' | 'green' | 'invertedGreen'
  transform?: 'capitalize'
}

const Button =
  styled('button') <IButton>`
  font-size: 1em;
  border: 1px solid;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.625em;
  max-width: 15.125rem;
  line-height: inherit;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;

  ${({size}) => {
    switch (size) {
      case 'small':
        return 'max-width: 10rem;';
      default:
        return '';
    }
  }};

  @media (${breakpoints.TabletV}) {
    font-size: 1.1875em;
    padding: 1.25rem 2.5em;
    max-width: 14rem;
  }

  ${({variant}) => {
    switch (variant) {
      case 'primary':
        return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
      case 'invertedPrimary':
        return 'color: #334d2b; background-color: #fff; border: 1px solid #334d2b;';
      case 'green':
        return 'color: #fff; background-color: #659254; border: 1px solid #659254;';
      case 'invertedGreen':
        return 'color: #659254; background-color: #eef2ec; border: 1px solid #659254;';
      default:
        return '';
    }
  }};

  ${({transform}) => {
    switch (transform) {
      case 'capitalize':
        return 'text-transform: capitalize';
      default:
        return '';
    }
  }};

  &:hover {
    ${({variant}) => {
    switch (variant) {
      case 'primary':
        return 'color: #fff; background-color: #1c3115; border: 1px solid #1c3115;';
      case 'invertedPrimary':
        return 'color: #334d2b; background-color: #92b784; border: 1px solid #92b784;';
      case 'green':
        return 'color: #fff; background-color: #334d2b; border: 1px solid #334d2b;';
      case 'invertedGreen':
        return 'color: #fff; background-color: #334d2b; border: 1px solid #659254;';
      default:
        return '';
    }
  }};
  }
`;

export default Button;
